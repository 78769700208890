/*
 * Copyright 2017-2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

const dict = {
	de: {
		'Loading...': 'Lädt...',
		'Sign In': 'Anmelden',
		'Sign Up': 'Registrieren',
		'Sign Out': 'Abmelden',
		'Sign in to your account': 'Melden Sie sich mit Ihrem Account an',
		Username: 'Benutzername',
		Password: 'Passwort',
		'Enter your username': 'Geben Sie Ihren Benutzernamen ein',
		'Enter your password': 'Geben Sie Ihr Passwort ein',
		'No account? ': 'Kein Account? ',
		'Forget your password? ': 'Passwort vergessen? ',
		'Reset password': 'Passwort zurücksetzen',
		'User does not exist': 'Dieser Benutzer existiert nicht',
		'User already exists': 'Dieser Benutzer existiert bereits',
		'Incorrect username or password': 'Falscher Benutzername oder falsches Passwort',
		'Invalid password format': 'Ungültiges Passwort-Format',
		'Create account': 'Hier registrieren',
		'Forgot Password': 'Passwort vergessen',
		'Change Password': 'Passwort ändern',
		'New Password': 'Neues Passwort',
		Email: 'Email',
		'Phone Number': 'Telefonnummer',
		'Confirm a Code': 'Code bestätigen',
		'Confirm Sign In': 'Anmeldung bestätigen',
		'Confirm Sign Up': 'Registrierung bestätigen',
		'Back to Sign In': 'Zurück zur Anmeldung',
		'Send Code': 'Code senden',
		Confirm: 'Bestätigen',
		'Resend Code': 'Code erneut senden',
		Submit: 'Abschicken',
		Skip: 'Überspringen',
		Verify: 'Verifizieren',
		'Verify Contact': 'Kontakt verifizieren',
		Code: 'Code',
		'Confirmation Code': 'Bestätigungs-Code',
		'Lost your code? ': 'Code verloren? ',
		'Account recovery requires verified contact information': 'Zurücksetzen des Account benötigt einen verifizierten Account',
		'Invalid phone number format': `Ungültiges Telefonummern-Format.
            Benutze eine Nummer im Format: +12345678900`,
		'Create Account': 'Account erstellen',
		'Have an account? ': 'Schon registriert? ',
		'Sign in': 'Anmelden',
		'Create a new account': 'Erstelle einen neuen Account',
		'Reset your password': 'Zurücksetzen des Passworts',
		'An account with the given email already exists.': 'Ein Account mit dieser Email existiert bereits.',
		'Username cannot be empty': 'Benutzername darf nicht leer sein',
		'Password attempts exceeded': 'Die maximale Anzahl der fehlerhaften Anmeldeversuche wurde erreicht'
	},
	fr: {
		'Loading...': "S'il vous plaît, attendez",
		'Sign In': 'Se connecter',
		'Sign Up': "S'inscrire",
		'Sign Out': 'Déconnexion',
		'Forgot Password': 'Mot de passe oublié',
		Username: "Nom d'utilisateur",
		Password: 'Mot de passe',
		'Change Password': 'Changer le mot de passe',
		'New Password': 'nouveau mot de passe',
		Email: 'Email',
		'Phone Number': 'Numéro de téléphone',
		'Confirm a Code': 'Confirmer un code',
		'Confirm Sign In': 'Confirmer la connexion',
		'Confirm Sign Up': "Confirmer l'inscription",
		'Back to Sign In': 'Retour à la connexion',
		'Send Code': 'Envoyer le code',
		Confirm: 'Confirmer',
		'Resend a Code': 'Renvoyer un code',
		Submit: 'Soumettre',
		Skip: 'Sauter',
		Verify: 'Vérifier',
		'Verify Contact': 'Vérifier le contact',
		Code: 'Code',
		'Account recovery requires verified contact information': 'La récupération du compte nécessite des informations de contact vérifiées',

		'User does not exist': "L'utilisateur n'existe pas",
		'User already exists': "L'utilisateur existe déjà",
		'Incorrect username or password': 'identifiant ou mot de passe incorrect',
		'Invalid password format': 'format de mot de passe invalide',
		'Invalid phone number format': `Format de numéro de téléphone invalide.
Veuillez utiliser un format de numéro de téléphone du +12345678900`,
		'Sign in to your account': 'Connectez-vous à votre compte',
		'Forget your password? ': 'Mot de passe oublié ? ',
		'Reset password': 'Réinitialisez votre mot de passe',
		'No account? ': 'Pas de compte ? ',
		'Create account': 'Créer un compte',
		'Create Account': 'Créer un compte',
		'Have an account? ': 'Déjà un compte ? ',
		'Sign in': 'Se connecter',
		'Create a new account': 'Créer un nouveau compte',
		'Reset your password': 'Réinitialisez votre mot de passe',
		'Enter your username': "Saisissez votre nom d'utilisateur",
		'Enter your password': 'Saisissez votre mot de passe',
		'An account with the given email already exists.': 'Un utilisateur avec cette adresse email existe déjà.',
		'Username cannot be empty': "Le nom d'utilisateur doit être renseigné"
	},

	es: {
		'Loading...': 'Espere por favor',
		'Sign In': 'Registrarse',
		'Sign Up': 'Regístrate',
		'Sign Out': 'Desconectar',
		'Forgot Password': 'Se te olvidó tu contraseña',
		Username: 'Nombre de usuario',
		Password: 'Contraseña',
		'Change Password': 'Cambia la contraseña',
		'New Password': 'Nueva contraseña',
		Email: 'Email',
		'Phone Number': 'Número de teléfono',
		'Confirm a Code': 'Confirmar un código',
		'Confirm Sign In': 'Confirmar inicio de sesión',
		'Confirm Sign Up': 'Confirmar Registrarse',
		'Back to Sign In': 'Volver a Iniciar sesión',
		'Send Code': 'Enviar código',
		Confirm: 'Confirmar',
		'Resend a Code': 'Reenviar un código',
		Submit: 'Enviar',
		Skip: 'Omitir',
		Verify: 'Verificar',
		'Verify Contact': 'Verificar contacto',
		Code: 'Código',
		'Account recovery requires verified contact information': 'La recuperación de la cuenta requiere información de contacto verificada',

		'User does not exist': 'el usuario no existe',
		'User already exists': 'El usuario ya existe',
		'Incorrect username or password': 'Nombre de usuario o contraseña incorrecta',
		'Invalid password format': 'Formato de contraseña inválido',
		'Invalid phone number format': `Formato de número de teléfono inválido.
Utilice el formato de número de teléfono +12345678900`
	},
	it: {
		Loading: 'Caricamento in corso',
		'Account recovery requires verified contact information': 'Ripristino del conto richiede un account verificati',
		'An account with the given email already exists.': 'Un account con questa email esiste già.',
		'Back to Sign In': 'Torna alla Login',
		'Change Password': 'Cambia la password',
		Code: 'Codice',
		Confirm: 'Conferma',
		'Confirm Sign In': 'Conferma di applicazione',
		'Confirm Sign Up': 'Registrazione Conferma',
		'Confirm a Code': 'Codice Conferma',
		'Confirmation Code': 'Codice di verifica',
		'Create Account': 'Crea account',
		'Create a new account': 'Creare un nuovo account',
		'Create account': 'Registrati',
		Email: 'E-mail',
		'Enter your password': 'Inserire la password',
		'Enter your username': 'Inserisci il tuo nome utente',
		'Forget your password?': 'Password dimenticata?',
		'Forgot Password': 'Password dimenticata',
		'Have an account? ': 'Già registrato?',
		'Incorrect username or password': 'Nome utente o password errati',
		'Invalid password format': 'Formato della password non valido',
		'Invalid phone number format': 'Utilizzo non valido Telefonummern formattare un numero nel formato :. 12.345.678,9 mille',
		'Lost your code?': 'Perso codice?',
		'New Password': 'Nuova password',
		'No account? ': 'Nessun account?',
		Password: 'Password',
		'Password attempts exceeded': 'Il numero massimo di tentativi di accesso falliti è stato raggiunto',
		'Phone Number': 'Numero di telefono',
		'Resend Code': 'Codice Rispedisci',
		'Reset password': 'Ripristina password',
		'Reset your password': 'Resetta password',
		'Send Code': 'Invia codice',
		'Sign In': 'Accesso',
		'Sign Out': 'Esci',
		'Sign Up': 'Iscriviti',
		'Sign in': 'Accesso',
		'Sign in to your account': 'Accedi con il tuo account a',
		Skip: 'Salta',
		Submit: 'Sottoscrivi',
		'User already exists': 'Questo utente esiste già',
		'User does not exist': 'Questo utente non esiste',
		Username: 'Nome utente',
		'Username cannot be empty': 'Nome utente non può essere vuoto',
		Verify: 'Verifica',
		'Verify Contact': 'Contatto verifica'
	},
	zh: {
		'Loading...': '请稍候',
		'Sign In': '登录',
		'Sign Up': '注册',
		'Sign Out': '退出',
		'Forgot Password': '忘记密码',
		Username: '用户名',
		Password: '密码',
		'Change Password': '改变密码',
		'New Password': '新密码',
		Email: '邮箱',
		'Phone Number': '电话',
		'Confirm a Code': '确认码',
		'Confirm Sign In': '确认登录',
		'Confirm Sign Up': '确认注册',
		'Back to Sign In': '回到登录',
		'Send Code': '发送确认码',
		Confirm: '确认',
		'Resend a Code': '重发确认码',
		Submit: '提交',
		Skip: '跳过',
		Verify: '验证',
		'Verify Contact': '验证联系方式',
		Code: '确认码',
		'Account recovery requires verified contact information': '账户恢复需要验证过的联系方式',

		'User does not exist': '用户不存在',
		'User already exists': '用户已经存在',
		'Incorrect username or password': '用户名或密码错误',
		'Invalid password format': '密码格式错误',
		'Invalid phone number format': '电话格式错误，请使用格式 +12345678900'
	}
};

export default dict;